.d2g-text-headline {
  color: #8E1E35 !important;
}

/* prettier-ignore */
.btn-boots-primary {
  color: #fff;
  font-weight: bold;
  background: #253F58;
  border-radius: 9px;
  width: 14rem;
  border: 0;
}
.btn-boots-primary:hover, .btn-boots-primary:focus {
  color: #fff;
  background: #4B756B;
}
@media (min-width: 1140px) {
  .btn-boots-primary {
    width: 25.569rem;
  }
}