@import "../../node_modules/bootstrap/scss/_functions";
@import "theme/functions";

@import "../../node_modules/bootstrap/scss/mixins";
@import "theme/mixins";

@import "user-variables";
@import "theme/variables";
@import "../../node_modules/bootstrap/scss/variables";

// 
// 
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.btn-boots-primary {
  color: $white;
  font-weight: bold;
  background: #253F58;
  border-radius: 9px;
  width: 14rem;
  border:0;
  &:hover,
  &:focus {
    color: $white;
    background: #4B756B;
  }
  @include media-breakpoint-up(xl) {
    width: 25.569rem;
  }
}